import { queryParamsSchema } from '../../shared/types';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

export const PathManager = () => {

    // Get URL query params
    const [searchParams] = useSearchParams();
    const queryParamsString = searchParams.toString();
    const { projectName } = useParams();

    // Get the query parameters with type safety
    const queries = Object.fromEntries(searchParams.entries());
    const { mode } = queryParamsSchema.parse(queries);

    switch (mode) {
        case 'recoverEmail':
            return <Navigate to={`/${projectName}/recoverEmail?${queryParamsString}`} replace />;
        case 'resetPassword':
            return <Navigate to={`/${projectName}/resetPassword?${queryParamsString}`} replace />;
        case 'verifyEmail':
            return <Navigate to={`/${projectName}/verifyEmail?${queryParamsString}`} replace />;
        default:
            throw new Error('Invalid action!');
    }
}
