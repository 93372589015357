import { z } from 'zod';

/** Enum schema for action mode */
export const modeSchema = z.enum(['resetPassword', 'recoverEmail', 'verifyEmail']);

/**
 * Query parameters for reset password URL
 * The conditional properties are set manually inside the Firebase Reset Password template in the "a" tag URL after the %LINK% text
 */
export const queryParamsSchema = z.object({
    mode: modeSchema,
    oobCode: z.string(),
    apiKey: z.string(),
    continueUrl: z.string().optional(),
    imageUrl: z.string().optional(),
});

// Obtain the type from the zod schema
export type TQueryParams = z.infer<typeof queryParamsSchema>;

// Type of action mode
export type TMode = z.infer<typeof modeSchema>;

/** Schema for password reset page props */
export const passwordActionPagePropsSchema = queryParamsSchema
    .omit({ mode: true })
    .extend({ projectName: z.string().optional() });

export type TPasswordActionPageProps = z.infer<typeof passwordActionPagePropsSchema>;

/** Schema for verify/recover email page props */
export const emailActionPagePropsSchema = passwordActionPagePropsSchema
    .omit({ imageUrl: true });

export type TEmailActionPageProps = z.infer<typeof emailActionPagePropsSchema>;

/** Zod schema for the password */
export const passwordSchema = z.string()
    .min(8, { message: 'Password must be at least 8 characters long.' })
    .regex(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,20}$/,
        {
            message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character.'
        }
    );

/** Values for reset password form */
export const resetPasswordValuesSchema = z.object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
});

export const resetPasswordValuesDefaults = z.object({
    password: resetPasswordValuesSchema.shape['password'].or(z.literal('')).default(''),
    confirmPassword: resetPasswordValuesSchema.shape['confirmPassword'].or(z.literal('')).default(''),
});

// Obtain the type from the zod schema
export type TResetPasswordValues = z.infer<typeof resetPasswordValuesSchema>;

export type TResetPasswordValuesKey = keyof TResetPasswordValues;
