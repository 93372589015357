import React, { InputHTMLAttributes } from 'react';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?:string;
}
/** Tailwind styled password input component with label and error helper */
const PasswordInput: React.FC<PasswordInputProps> = ({label, error, ...rest}) => {

    return (
        <div>
            <div className="flex items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
            </div>
            <div className="mt-2">
                <input
                    {...rest}
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
            {error && <div className="text-red-600 break-words mt-1 text-xs">{error}</div>}
        </div>
    );

};

export default PasswordInput;
