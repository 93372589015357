import { FirebaseError } from "firebase/app";
import { ZodError } from "zod";

/** Assertion check */
export function assert(value: boolean, message = 'Assertion Error'): asserts value {
    if (value === true) return;
    throw new Error(message);
}

/** Get error message from possible errors */
export function errorToMessage(e: unknown): string {

    if (e instanceof FirebaseError) {
        switch (e.code) {

            case 'auth/api-key-not-valid.-please-pass-a-valid-api-key.':
                return 'Invalid API Key. Please contact support.';

            case 'auth/invalid-action-code':
                return 'Your request has already been approved, please try to login.';

            case 'auth/expired-action-code':
                return 'Your request has expired, please request a new link and try again.';

            default:
                return e.code
        }
    }

    if (e instanceof ZodError) {
        const issue = e.issues[0];
        if (issue.code === 'invalid_type') return 'Invalid URL parameters.'
        return issue.message;
    }

    if (e instanceof Error) {
        return e.message;
    }

    return 'Unknown error. Please contact support.';
}
