import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ResetPasswordPage } from './reset-password-page/reset-password-page';
import { VerifyEmailPage } from './verify-email-page/verify-email-page';
import { RecoverEmailPage } from './recover-email-page/recover-email-page';
import { PathManager } from '../components/path-manager/path-manager';
import { ErrorBoundary } from '../components/error-boundary/error-boundary';
import { SnackbarProvider } from 'notistack';

export const AppRoutes: React.FC = () => {

    const ErrorBoundaryLayout = (
        <ErrorBoundary key={'routes'}>
            <SnackbarProvider>
                <Outlet />
            </SnackbarProvider>
        </ErrorBoundary>
    );

    const router = createBrowserRouter([
        {
            element: ErrorBoundaryLayout,
            children: [
                {
                    path: '/:projectName',
                    element: <PathManager />,
                },
                {
                    path: '/:projectName/verifyEmail',
                    element: <VerifyEmailPage />,
                },
                {
                    path: '/:projectName/recoverEmail',
                    element: <RecoverEmailPage />,
                },
                {
                    path: '/:projectName/resetPassword',
                    element: <ResetPasswordPage />,
                },
            ]
        }
    ]);

    return <RouterProvider router={router} />;

}
