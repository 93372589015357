import { applyActionCode } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { emailActionPagePropsSchema } from '../../shared/types';
import { errorToMessage } from '../../shared/utils';
import { useSnackbar } from 'notistack';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

type TVerifyStatus = 'inProgress' | 'error' | 'success';

/**
 * Displays a button to verify an email address
 * @returns
 */
export const VerifyEmailPage: React.FC = () => {

    useEffect(() => {
        document.title = "Verify Email Address";
    }, []);

    // Get URL query params
    const [searchParams] = useSearchParams();
    const { projectName } = useParams();

    // Get the query parameters with type safety
    const queries = Object.fromEntries(searchParams.entries());
    const { oobCode, apiKey, continueUrl } = emailActionPagePropsSchema.parse(queries);

    const { auth } = useAuth(apiKey);
    const [status, setStatus] = useState<TVerifyStatus>('inProgress');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    if (auth === null) {
        throw new Error('Authentication failed! Please try again.');
    }

    /** Redirects to continueUrl */
    const handleSuccess = () => {

        if (continueUrl === undefined) return;

        // If there is a confirmUrl inform the user that they will be redirected back to the app
        enqueueSnackbar('You will be redirected back to the app soon.', { variant: 'success' });

        // Redirect to continueUrl
        setTimeout(() => {
            window.location.href = continueUrl;
        }, 3000)

        // clearTimeout(timeout);

    };

    const handleError = () => {

        if (continueUrl === undefined) return;

        // If there is a confirmUrl inform the user that they will be redirected back to the app
        enqueueSnackbar('You will be redirected back to the app soon.', { variant: 'error' });

        const url = new URL(continueUrl);
        console.info(url);

        // Redirect to continueUrl
        setTimeout(() => {
            window.location.href = `${url.protocol}//${url.hostname}`;
        }, 3000)

        // clearTimeout(timeout);

    };

    /** Verifies email address with action code */
    const verify = async () => {

        if (status !== 'inProgress') return;

        try {

            // Verify email address
            await applyActionCode(auth, oobCode);
            setStatus('success');
            handleSuccess();

        } catch (e) {

            console.error(e);
            setStatus('error');
            setErrorMessage(errorToMessage(e));
            handleError();

        }

    }



    verify();

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                {status === 'inProgress' && <div className="top-1/2 left-1/2 w-16 h-16 rounded-full border-8 border-blue-500 border-opacity-100 border-t-transparent animate-spin" />}
                {(status === 'error' && errorMessage !== null) && (
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        {errorMessage}
                    </h1>
                )}
                {status === 'success' && (
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Successfully Verified Email Address {projectName && `For ${projectName}`}!
                    </h1>
                )}
            </div>
        </main>
    );

}
