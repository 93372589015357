import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { OptionsObject, useSnackbar } from 'notistack';
import React, { FormEvent, useState, useEffect } from 'react';
import {
    TResetPasswordValuesKey, TResetPasswordValues,
    resetPasswordValuesSchema, resetPasswordValuesDefaults,
    passwordActionPagePropsSchema
} from '../../shared/types';
import PasswordInput from '../../components/password-input/password-input';
import { z } from 'zod';
import { errorToMessage } from '../../shared/utils';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';


/**
 * Displays a form to enter a new password
 * Resets password
 * @returns
 */
export const ResetPasswordPage: React.FC = () => {

    useEffect(() => {
        document.title = "Reset Password";
    }, []);


    // Get URL query params
    const [searchParams] = useSearchParams();
    const { projectName } = useParams();

    // Get the query parameters with type safety
    const queries = Object.fromEntries(searchParams.entries());
    const { oobCode, apiKey, continueUrl, imageUrl } = passwordActionPagePropsSchema.parse(queries);

    const { auth } = useAuth(apiKey);
    const [passwordValues, setPasswordValues] = useState<TResetPasswordValues>(resetPasswordValuesDefaults.parse({}));
    const [errors, setErrors] = useState<Partial<TResetPasswordValues>>({});
    const [busy, setBusy] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    if (auth === null) {
        throw new Error('Authentication failed! Please try again.');
    }

    /**
     * Validates the password and submits the password reset request
     * @param event
     */
    const resetPassword = async (event: FormEvent<HTMLFormElement>) => {

        try {

            setBusy(true);

            event.preventDefault();

            const { password, confirmPassword } = passwordValues;

            // Check for matching passwords
            if (!z.string().refine(v => v === password).safeParse(confirmPassword).success) {
                throw new Error('Passwords do not match.');
            }

            // Ensure that the password meets the validation criteria
            resetPasswordValuesSchema.shape['password'].parse(password);

            // Ensure that the action code for the password reset is still valid
            await verifyPasswordResetCode(auth, oobCode);

            // Reset password
            await confirmPasswordReset(auth, oobCode, password);

            handleSuccess();

        } catch (e) {

            console.error(e);
            enqueueSnackbar(errorToMessage(e), { variant: 'error' });

        } finally {

            setBusy(false);

        }

    };

    /** Displays success message and redirects to continueUrl */
    const handleSuccess = () => {

        const snackbarOptions: OptionsObject = { variant: 'success' };
        const baseSuccessText = 'Your password has been reset successfully!';

        // Reset password values
        setPasswordValues(resetPasswordValuesDefaults.parse({}));

        // If there is no continueUrl, just print the success message
        if (continueUrl === undefined) {
            enqueueSnackbar(baseSuccessText, snackbarOptions);
            return;
        }

        // If there is a confirmUrl inform the user that they will be redirected back to the app
        enqueueSnackbar(`${baseSuccessText} You will be redirected back to the app soon.`, snackbarOptions);

        // Redirect to continueUrl
        setTimeout(() => {
            window.location.href = continueUrl;
        }, 3000)

    }

    /**
     * Sets new password value, validates it with zod and sets error messages
     * @param field
     * @param value
     * @returns
     */
    const handleFieldChange = (field: TResetPasswordValuesKey, value: string) => {

        setPasswordValues(curr => ({ ...curr, [field]: value }))

        // Validate the new value with zod
        const parseResult = resetPasswordValuesSchema.shape[field].safeParse(value);

        if (parseResult.success) {
            setErrors({ ...errors, [field]: undefined });
            return;
        }

        // Set the error message for the field
        setErrors({ ...errors, [field]: parseResult.error.issues[0].message });

    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                {imageUrl !== undefined && (
                    <img
                        className="mx-auto h-20 w-auto"
                        src={imageUrl}
                        alt="Logo"
                    />
                )}
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Reset Password {projectName !== undefined && `For ${projectName}`}
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={resetPassword}>

                    <PasswordInput
                        label="Password"
                        error={errors.password}
                        type="password"
                        value={passwordValues?.password}
                        onChange={(e) => handleFieldChange("password", e.target.value)}
                        autoComplete={'new-password'}
                        required />

                    <PasswordInput
                        label="Confirm Password"
                        error={errors.confirmPassword}
                        type="password"
                        value={passwordValues?.confirmPassword}
                        onChange={(e) => handleFieldChange("confirmPassword", e.target.value)}
                        autoComplete='new-password'
                        required />

                    <div>
                        <button
                            type="submit"
                            disabled={busy || !resetPasswordValuesSchema.safeParse(passwordValues).success}
                            className="flex h-8 w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm cursor-pointer hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-500"
                        >
                            {busy ? (
                                <div className="top-1/2 left-1/2 w-4 h-4 rounded-full border-2 border-white border-opacity-100 border-t-transparent animate-spin" />
                            ) : (
                                <div>Submit</div>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

}
