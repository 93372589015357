import React from 'react';
import { errorToMessage } from '../../shared/utils';

type props = {
    children?: React.ReactNode;
    displayRestart?: boolean;
}

type state = {
    hasError: boolean;
    error?: Error;
}

export class ErrorBoundary extends React.Component<props, state> {

    constructor(props: props) {

        super(props);

        this.state = {
            hasError: false
        };

    }

    // Update state so the next render will show the fallback UI.
    static getDerivedStateFromError(error: Error): { hasError: boolean, error: Error } {
        console.error('getDerivedStateFromError', error);
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: unknown): void {
        console.error('componentDidCatch', error, errorInfo);
    }

    getErrorMessage(): string {

        const e = this.state.error;

        return errorToMessage(e);

    }

    render(): React.ReactNode {

        // You can render any custom fallback UI
        if (this.state.hasError) {

            return (
                <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                    <p className="text-base font-semibold text-indigo-600">{this.state.error?.name ?? 'Error'}</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{this.getErrorMessage()}</h1>
                    </div>
                </main>
            );
        }

        return this.props.children;

    }

}
