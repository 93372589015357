import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { useMemo } from 'react';

const useAuth = (apiKey: string) => {
    // Initialise the firebase app related to the request
    const firebaseApp = useMemo(() => initializeApp({ apiKey }), [apiKey]);
    const auth = useMemo(() => getAuth(firebaseApp), [firebaseApp]);
    return { auth };
};

export { useAuth };
