import { applyActionCode, checkActionCode } from 'firebase/auth';
import { OptionsObject, useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { emailActionPagePropsSchema } from '../../shared/types';
import { errorToMessage } from '../../shared/utils';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';


type TVerifyStatus = 'awaiting' | 'inProgress' | 'error' | 'success';

/**
 * Displays a button to revert email address change by recovering the old one
 * @returns
 */
export const RecoverEmailPage: React.FC = () => {

    useEffect(() => {
        document.title = "Recover Email Address";
    }, []);

    // Get URL query params
    const [searchParams] = useSearchParams();
    const { projectName } = useParams();

    // Get the query parameters with type safety
    const queries = Object.fromEntries(searchParams.entries());
    const { oobCode, apiKey, continueUrl } = emailActionPagePropsSchema.parse(queries);

    const { auth } = useAuth(apiKey);
    const [status, setStatus] = useState<TVerifyStatus>('awaiting');
    const [email, setEmail] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    if (auth === null) {
        throw new Error('Authentication failed! Please try again.');
    }

    useEffect(() => {
        (async () => {
            const info = await checkActionCode(auth, oobCode);
            setEmail(info.data.email ?? null);
        })();
    }, [auth, oobCode]);

    /**
     * Applies action code to recover email address
     * @param event
     */
    const recover = async () => {

        try {

            setStatus('inProgress');

            // Recover email address
            await applyActionCode(auth, oobCode);

            handleSuccess();

        } catch (e) {

            console.error(e);
            setStatus('error');
            enqueueSnackbar(errorToMessage(e), { variant: 'error' });

        } finally {

            setStatus('success');

        }

    };

    /** Displays success message and redirects to continueUrl */
    const handleSuccess = () => {

        const snackbarOptions: OptionsObject = { variant: 'success' };
        const baseSuccessText = 'Your Email Address Has Been Recovered!';

        // If there is no continueUrl, just print the success message
        if (continueUrl === undefined) {
            enqueueSnackbar(baseSuccessText, snackbarOptions);
            return;
        }

        // If there is a confirmUrl inform the user that they will be redirected back to the app
        enqueueSnackbar(`${baseSuccessText} You will be redirected back to the app soon.`, snackbarOptions);

        // Redirect to continueUrl
        setTimeout(() => {
            window.location.href = continueUrl;
        }, 3000)

    }

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Recover Email Address {projectName && `For ${projectName}`}</h1>
                {status === 'success' && <p className="text-base font-semibold text-indigo-600">Your Email Address Has Been Recovered!</p>}
                {(status === 'awaiting' && email !== null) && (
                    <p className="mt-6 text-base leading-7 text-gray-600">Recover email {email}?</p>
                )}
                {status !== 'error' && (
                    <form onSubmit={recover}>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <button
                                type="submit"
                                disabled={status === 'inProgress' || status === 'success'}
                                className="flex h-8 w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm cursor-pointer hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-500"
                            >
                                {status === 'inProgress' ? (
                                    <div className="top-1/2 left-1/2 w-4 h-4 rounded-full border-2 border-white border-opacity-100 border-t-transparent animate-spin" />
                                ) : (
                                    <div>Verify</div>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </main>
    );

}
